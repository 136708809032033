//TODO import bundles like { initializeApp } from "firebase/app" instead of all of firebase
import * as firebase from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import * as firebaseFirestore from "firebase/firestore";
import * as firebaseMessaging from 'firebase/messaging';
import { getAnalytics } from "firebase/analytics";
const config = {
  apiKey: "AIzaSyBob_rFF4PnchaJHzgR2V9jwOxYIP3hQTk",
  authDomain: "defi-notifications-24653.firebaseapp.com",
  projectId: "defi-notifications-24653",
  storageBucket: "defi-notifications-24653.appspot.com",
  messagingSenderId: "355584130061",
  appId: "1:355584130061:web:b9fe42a7129f62600f2944",
  measurementId: "G-JV664VCCMT"
}
export const app = firebase.initializeApp(config);
export const provider = new GoogleAuthProvider();
export const auth = getAuth(app);
export const firestore = firebaseFirestore.getFirestore(app);
export const messaging = firebaseMessaging.getMessaging(app);
export const analytics = getAnalytics(app);
export default firebase;