import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom"
import { auth, provider, firestore, messaging } from './firebase.js';
import { collection, setDoc, doc } from "firebase/firestore";

import { signInWithPopup } from 'firebase/auth';
import ItemList from './ItemList.js'
import AddItemButton from './AddItemButton'
import NewItem from './NewItem.js'
import EmailNotificationPreference from './EmailNotificationPreference.js'
import PushNotificationPreference from './PushNotificationPreference.js';
import TimeZonePreference from './TimeZonePreference.js';
import PushNotificationsNotSupported from './PushNotificationsNotSupported.js';
import './App.css';
import MarketingPage from './MarketingPage.js';
import NewVersionNotification from './newVersionNotification/NewVersionNotification.js';
import NotPaidNotification from './NotPaidNotification/NotPaidNotification.js';
import * as firebaseMessaging from 'firebase/messaging';
import * as serviceWorkerRegistration from './registerServiceWorker.js';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider, styled } from 'baseui';

const engine = new Styletron();

const End = styled('div', {
  display: 'flex',
});

class App extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      user: null,
    }
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    // firestore.settings({
    //   timestampsInSnapshots: true
    // });
  }

  componentDidMount() {
    serviceWorkerRegistration.register();
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        await this.initializeDocuments();
        this.setState({ user });
      }
    });
  }

  logout() {
    auth.signOut()
      .then(() => {
        this.setState({
          user: null
        });
      });
  }

  login() {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        this.setState({
          user
        });
        // window.fbq('track', 'CompleteRegistration');
      });
  }

  async initializeDocuments() {
    await setDoc(doc(collection(firestore, "users"), auth.currentUser.uid), {
      name: auth.currentUser.displayName || "User",
      email: auth.currentUser.email
    }, { merge: true })
      .then(() => console.log("User exists"))
      .catch((error) => console.log("Error creating user " + error + " User may already exist"))
  }

  render() {
    return (
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
          {this.state.user ?
            <div>
              <header className="center-text mdl-color-text--white mdl-color--light-blue-700">
                <div id="user-container">
                  <div id="user-name">{auth.currentUser.email}</div>
                  <button className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-color-text--white" onClick={this.logout}>Log Out</button>
                </div>
              </header>
              {/* <NotPaidNotification /> */}
              <Router>
                <div className="webapp">
                  <div>
                    <Route exact path="(/|/index.html)" render={props => firebaseMessaging.isSupported() ? <PushNotificationPreference /> : <PushNotificationsNotSupported />} />
                    <Route exact path="(/|/index.html)" component={EmailNotificationPreference} />
                    <Route exact path="(/|/index.html)" component={TimeZonePreference} />
                  </div>
                  <div className='large-margin-top'>
                    <Route exact path="(/|/index.html)" component={ItemList} />
                    <End>
                      <div className='flex'></div>
                      <Route exact path="(/|/index.html)" component={AddItemButton} />
                    </End>
                    <Route exact path="/new-item" component={NewItem} />
                  </div>
                </div>
              </Router>
            </div>
            :
            <MarketingPage login={this.login} />

          }
          <NewVersionNotification />
        </BaseProvider>
      </StyletronProvider>
    );
  }
}

export default App;
