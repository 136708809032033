const NEW_VERSION_READY = "newVersionReady";

let listener;
let needsCalling;

const onNewServiceWorkerInstalled = () => {
    window.caches.open(NEW_VERSION_READY).then(() => {
        if (listener) {
            listener();
        } else {
            needsCalling = true;
        }
    });
}

const setOnNewServiceWorkerInstalledListener = (newListener) => {
    listener = newListener;
    if (needsCalling) {
        newListener();
        needsCalling = false;
    }
}

const isNewVersionReady = () => {
    return window.caches.has(NEW_VERSION_READY);
}

module.exports = {
    onNewServiceWorkerInstalled,
    setOnNewServiceWorkerInstalledListener,
    isNewVersionReady,
}
