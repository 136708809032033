import { messaging, auth, firestore } from './firebase.js';
import { updateDoc, doc, collection, arrayUnion } from 'firebase/firestore';
import { getToken, onMessage } from 'firebase/messaging';

class PushNotificationService {

    uploadLatestToken() {
        getToken(messaging, {
            vapidKey: "BKznXrOZBtd4Y5cg0GGb6xsC0kM0rOzMBiN0Zmvp5niyHFBAJOZ_BJe-ErkAcDIAu2bCl9Zai21KGlgrO8TWVfU",
        }).then((refreshedToken) => {
            if (refreshedToken) {
                console.log('Token refreshed.');
                this.sendTokenToServer(refreshedToken);
            } else {
                console.log("No push token for this device");
            }
        }).catch((err) => {
            console.log('Unable to retrieve refreshed token ', err);
        });
    }

    listenForMessages() {
        console.log('listening for msgs');
        onMessage(messaging, (payload) => {
            console.log('Message received in foreground. ', payload);
            navigator.serviceWorker.ready.then((registration) => {
                console.log('showing notification from foreground');
                const notification = payload.notification;
                registration.showNotification(notification.title, {
                    body: notification.body,
                });
            });
        });
    }

    sendTokenToServer(currentToken) {
        console.log('Sending token to server...');
        return updateDoc(doc(collection(firestore, "users"), auth.currentUser.uid),
            {
                pushTokens: arrayUnion(currentToken),
            }
        ).catch(error => console.log("Error sending token to server: " + error))
    }

    subscribeUser() {
        console.log('requesting notification permission');
        return Notification.requestPermission().then(() => {
            console.log('Notification permission granted.');
            this.listenForMessages();
            this.uploadLatestToken();
        });
    }

    unSubscribeUser() {
        //why do I seemingly not need to unhook my onMessage listener?
        console.log('todo');
    }
}

export default PushNotificationService;
