import React, { Component } from 'react';
import './NotPaidNotification.css';
import { auth, firestore } from '../firebase.js';
import { onSnapshot, doc, collection, getDoc } from 'firebase/firestore';

class NotPaidNotification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            paid: true,
        };
        this.processDocSnap = this.processDocSnap.bind(this);
    }

    async componentDidMount() {
        try {
            const docSnap = await getDoc(doc(collection(firestore, "users"), auth.currentUser.uid));
            this.processDocSnap(docSnap);
        } catch (error) {
            console.log("Error getting user if paid: " + error);
        }
        onSnapshot(doc(collection(firestore, "users"), auth.currentUser.uid), {
            next: this.processDocSnap,
            error: error => console.log("Error getting user if paid: " + error)
        });
    }

    processDocSnap(docSnap) {
        if (docSnap.exists()) {
            console.log(`setstate paid ${docSnap.get('paid')}`);
            this.setState({
                paid: docSnap.get('paid'),
            });
        } else {
            console.log("Couldn't find user for if paid");
        }
    }

    render() {
        if (!this.state.paid) {
            return (
                <div
                    className="vertical center"
                >
                    <div className="flex" />
                    <div className="content">Account inactive. Message Chip#1249 on Discord or <a href="https://twitter.com/theMacroChip" target="blank">twitter.com/theMacroChip</a> to arrange payment.
                        Feel free to look around in the meantime.</div>
                    <div className="flex" />
                </div>
            )
        } else {
            return null;
        }
    }
}

export default NotPaidNotification;
