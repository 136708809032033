import React, { Component } from 'react';
import { auth, firestore } from './firebase.js';
import { collection, setDoc, doc, onSnapshot, updateDoc } from "firebase/firestore";
import './App.css';
import "@material/checkbox/dist/mdc.checkbox.css";
import "@material/form-field/dist/mdc.form-field.css";
import QueueItem from './QueueItem.js';

class ItemList extends Component {
    constructor() {
        super();
        this.state = {
            items: []
        }
    }

    componentDidMount() {
        this.fetchList();
    }

    fetchList() {
        onSnapshot(collection(doc(collection(firestore, "users"), auth.currentUser.uid), "monitors"),
            {
                next: doc => {
                    let newItems = [];
                    doc.forEach(docSnap => {
                        const item = docSnap.data();
                        newItems.push({
                            id: docSnap.id,
                            item: {
                                address: item.address,
                                utilization: item.utilization,
                            }
                        });
                    });
                    console.log("item collection update");
                    this.setState({ items: newItems });
                }, error: error => {
                    console.log("error getting item list: ", error);
                }
            });
    }

    makeList() {
        return (
            this.state.items.map(item =>
                <div key={item.id}>
                    <QueueItem
                        id={item.id}
                        item={item.item}
                    />
                </div>
            )
        )
    }

    render() {
        return (
            <div className="thick">
                <h4>Addresses you are monitoring:</h4>
                {this.makeList()}
            </div>
        )
    }
}

export default ItemList;