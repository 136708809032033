import React, { Component } from 'react'

class PushNotificationsNotSupported extends Component {

    render() {
        return (
            <div className="margin-top">
                Push notifications not supported on this device
            </div>
        )
    }
}

export default PushNotificationsNotSupported;
