import React, { Component } from 'react'
import NewServiceWorker from './NewServiceWorker.js'
import './NewVersionNotification.css';

class NewVersionNotification extends Component {

    constructor(props) {
        super(props)
        this.state = {}
        NewServiceWorker.isNewVersionReady().then(show => this.setState({ show }))
            .catch(error => console.log("Error getting isNewVersionReady: " + error));
        this.serviceWorkerReload = this.serviceWorkerReload.bind(this);
    }

    componentDidMount() {
        NewServiceWorker.setOnNewServiceWorkerInstalledListener(() => {
            this.setState({
                show: true,
            });
        });
    }

    componentWillUnmount() {
        NewServiceWorker.setOnNewServiceWorkerInstalledListener(null);
    }

    serviceWorkerReload() {
        window.navigator.serviceWorker.getRegistration().then(registration => {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        })
    }

    render() {
        return (
            this.state.show ?
                <div id="newVersionNotification">
                    <a onClick={this.serviceWorkerReload}>A new version of the app is available. Click here to update.</a>
                </div>
                :
                null
        )
    }
}

export default NewVersionNotification;
