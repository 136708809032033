import React, { Component } from 'react';
import './App.css';
import { auth, firestore } from "./firebase.js";
import { collection, addDoc, doc } from "firebase/firestore";
import alerter from './utils/alerter.js';
import { Redirect } from 'react-router-dom';

class NewItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            utilization: "10.0",
        }
        this.newItem = this.newItem.bind(this)
        this.onTextChange = this.onTextChange.bind(this);
        this.onUtilizationChange = this.onUtilizationChange.bind(this);
    }

    newItem() {
        let address = this.state.text; //TODO validate that it's an address
        let utilization = parseFloat(this.state.utilization) / 100;
        if (!(utilization >= 0.10)) {
            alerter("Borrow Utilization alerts must be for 10% or higher.");
            return;
        }
        if (!address.trim().match(/^\w{58}$/)) {
            alerter("Please enter an Algorand address.");
        } else {
            addDoc(collection(doc(collection(firestore, "users"), auth.currentUser.uid), "monitors"), {
                address,
                utilization,
            })
                .then(doc => {
                    alerter("Success!");
                    this.setState({ goHome: true });
                })
                .catch(error => {
                    alerter("Error. " + JSON.stringify(error));
                });
        }
    }

    onTextChange(e) {
        this.setState({ text: e.target.value })
    }

    onUtilizationChange(e) {
        this.setState({ utilization: e.target.value });
    }

    render() {
        return this.state.goHome ? (<Redirect push to="/" />) : (
            <div>
                <h4 className="margin-top" >Monitor New Address:</h4>
                <div>
                    <span className="margin-right">Address to monitor:</span>
                    <input className="margin-right" type="text" autoFocus={true} placeholder="The address to monitor" value={this.state.text} onChange={this.onTextChange}>
                    </input>
                </div>
                <div>
                    <span className="margin-right">Borrow Utilization percent to alert at:</span>
                    <input className="margin-right smol" type="number" placeholder="Borrow Utilization percent to alert at" value={this.state.utilization} onChange={this.onUtilizationChange}>
                    </input>
                    <span>%</span>
                </div>
                <button onClick={this.newItem} className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored">
                    <i className="material-icons">send</i>
                </button>
            </div>
        )
    }
}

export default NewItem;