import React, { Component } from 'react';
import { auth, firestore } from './firebase.js';
import { collection, setDoc, doc, onSnapshot, updateDoc } from "firebase/firestore";
import MaterialCheckbox from './MaterialCheckbox.js';
import PushNotificationService from './PushNotificationService.js';

class PushNotificationPreference extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pushNotifications: false
        }
        this.onItemChecked = this.onItemChecked.bind(this);
        this.pushNotificationService = new PushNotificationService();
    }

    componentDidMount() {
        onSnapshot(doc(collection(firestore, "users"), auth.currentUser.uid),
            {
                next: doc => {
                    if (doc.exists()) {
                        this.setState({ pushNotifications: doc.data().pushNotifications });
                        if (doc.data().pushNotifications) {
                            this.pushNotificationService.subscribeUser();
                        } else {
                            this.pushNotificationService.unSubscribeUser();
                        }
                    } else {
                        console.log("Couldn't find user for push preferences");
                    }
                },
                error: error => console.log("Error getting user for push preferences: " + error)
            });
    }

    onItemChecked(e) {
        let checked = e.target.checked;
        updateDoc(doc(collection(firestore, "users"), auth.currentUser.uid), {
            pushNotifications: checked
        })
            .then(() => {
                console.log("Push preferences updated")
            })
            .catch(error => console.log("Push preferences error: " + error));
    }

    render() {
        return (
            <div className="mdc-form-field margin-top margin-right">
                <div className="mdc-checkbox">
                    <input type="checkbox" checked={this.state.pushNotifications ? "checked" : ""}
                        className="mdc-checkbox__native-control"
                        id="pushNotifications"
                        onChange={this.onItemChecked} />
                    <MaterialCheckbox />
                </div>
                <label htmlFor="pushNotifications">Push notifications</label>
            </div>
        )
    }
}

export default PushNotificationPreference;