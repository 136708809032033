import React, { Component } from 'react';
import { auth, firestore } from './firebase.js';
import { collection, setDoc, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { TimezonePicker } from "baseui/timezonepicker";
import { styled } from 'baseui';
import "./TimeZonePreference.css"

class TimeZonePreference extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.onTimeZoneChanged = this.onTimeZoneChanged.bind(this);
    }

    onTimeZoneChanged(tz) {
        updateDoc(doc(collection(firestore, "users"), auth.currentUser.uid), {
            timeZone: tz
        })
            .then(() => {
                console.log("Time zone updated")
            })
            .catch(error => console.log("Time zone error: " + error));
    }

    componentDidMount() {
        onSnapshot(doc(collection(firestore, "users"), auth.currentUser.uid),
            {
                next: doc => {
                    if (doc.exists()) {
                        this.setState({ timeZone: doc.data().timeZone, loaded: true });
                    } else {
                        console.log("Couldn't find user for time zone");
                    }
                },
                error: error => console.log("Error getting user for time zone: " + error)
            });
    }

    render() {
        return this.state.loaded ? (
            <div>
                <div>Alert display timezone:</div>
                <div className='tz-picker'>
                    <TimezonePicker
                        value={this.state.timeZone}
                        onChange={({ id }) => this.onTimeZoneChanged(id)}
                    />
                </div>
            </div>
        ) : (<div></div>);
    }
}

export default TimeZonePreference;
