import React, { Component } from 'react';
import LandingPageBehaviors from './LandingPageBehaviors';

import "./css/bootstrap.min.css";
import "./css/all.min.css";
import "./css/simple-line-icons.css";
import "./css/new-age.css";

class MarketingPage extends Component {

    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
                    <div className="container">
                        <a className="navbar-brand js-scroll-trigger" href="#page-top">DeFi Notifications</a>
                        <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                            Menu
                            <i className="fas fa-bars"></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item btn-outline">
                                    <a className="nav-link" onClick={this.props.login}>Log In</a>
                                </li>
                                <li className="nav-item btn-outline">
                                    <a className="nav-link js-scroll-trigger" href="#features">Why DeFi Notifications?</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>


                <header className="masthead">
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-lg-7 my-auto">
                                <div className="header-content mx-auto">
                                    <h1 className="">Avoid liquidation</h1>
                                    <hr />
                                    <div className="mb-5 section-heading">
                                        <h2>We monitor your DeFi positions so you don't have to.</h2>
                                        <h2>Get notified to avoid being liquidated.</h2>
                                    </div>
                                    <a onClick={this.props.login} className="btn btn-outline btn-xl js-scroll-trigger">Get started</a>
                                </div>
                            </div>
                            <div className="col-lg-5 my-auto">
                                <div className="device-container">
                                    <div className="device-mockup iphone6_plus portrait white">
                                        <div className="device">
                                            <div className="screen">
                                                <img src="img/notification.png" className="img-fluid good-border" alt="Receiving a notification" title="Receiving a notification" />
                                            </div>
                                            <div className="button">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <section className="features" id="features">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 my-auto">
                                <div className="device-container">
                                    <div className="device-mockup iphone6_plus portrait white">
                                        <div className="device">
                                            <div className="screen">
                                                <img src="img/add-address.png" className="img-fluid good-border" alt="Adding an address to monitor" title="Adding an address to monitor" />
                                            </div>
                                            <div className="button">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 my-auto">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="feature-item">
                                                <i className="icon-screen-smartphone text-primary"></i>
                                                <h3>Notifications</h3>
                                                <p className="text-muted">Get notified by email or push when your position reaches your custom utilization</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="feature-item">
                                                <i className="icon-share text-primary"></i>
                                                <h3>Better than price notifications</h3>
                                                <p className="text-muted">Simple price movement notifications do not account for your specific leverage and crypto position. It's better to track utilization directly</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="feature-item">
                                                <i className="icon-diamond text-primary"></i>
                                                <h3>Avoid the fees that liquidators take</h3>
                                                <p className="text-muted">AlgoFi liquidators take 7%. That's a guaranteed loss</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="feature-item">
                                                <i className="icon-list text-primary"></i>
                                                <h3>Simple &amp; Clean</h3>
                                                <p className="text-muted">Get started in 25 seconds</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cta">
                    <div className="cta-content">
                        <div className="container center-children">
                            <h2>Stop refreshing prices</h2>
                            <a onClick={this.props.login} className="btn btn-outline btn-xl js-scroll-trigger">Get Started!</a>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </section>

                <footer>
                    <div className="container">
                        <p>Made by <a href="https://twitter.com/theMacroChip" target="blank">Chip Thien</a>. All Rights Reserved.</p>
                    </div>
                </footer>

                <LandingPageBehaviors />

            </div>
        );
    }
}

export default MarketingPage;
