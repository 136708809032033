import React, { Component } from 'react';

class LandingPageBehaviors extends Component {

    componentDidMount() {
        const $ = window.$;
        $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
            // because it's from the landing page code and I don't want to re-test it
            // eslint-disable-next-line eqeqeq
            if (window.location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && window.location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: (target.offset().top - 48)
                    }, 1000, "easeInOutExpo");
                    return false;
                }
            }
        });

        // Closes responsive menu when a scroll trigger link is clicked
        $('.js-scroll-trigger').click(function () {
            $('.navbar-collapse').collapse('hide');
        });

        // Activate scrollspy to add active class to navbar items on scroll
        $('body').scrollspy({
            target: '#mainNav',
            offset: 54
        });

        // Collapse Navbar
        this.navbarCollapse = function () {
            if ($("#mainNav").offset().top > 100) {
                $("#mainNav").addClass("navbar-shrink");
            } else {
                $("#mainNav").removeClass("navbar-shrink");
            }
        };
        // Collapse now if page is not at top
        this.navbarCollapse();
        // Collapse the navbar when page is scrolled
        $(window).scroll(this.navbarCollapse);
    }

    componentWillUnmount() {
        const $ = window.$;
        $(window).off("scroll", this.navbarCollapse);
    }

    render() {
        return (
            <div>
            </div>
        )
    }
}

export default LandingPageBehaviors;