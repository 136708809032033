import React from 'react';

const MaterialCheckbox = () => (
    <div className="mdc-checkbox__background">
        <svg className="mdc-checkbox__checkmark"
            viewBox="0 0 24 24">
            <path className="mdc-checkbox__checkmark-path"
                fill="none"
                d="M1.73,12.91 8.1,19.28 22.79,4.59" />
        </svg>
        <div className="mdc-checkbox__mixedmark"></div>
    </div>
);

export default MaterialCheckbox;
