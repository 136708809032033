import React, { Component } from 'react';
import { auth, firestore } from './firebase.js';
import { doc, collection, deleteDoc } from 'firebase/firestore';

class QueueItem extends Component {

    constructor(props) {
        super(props);
        this.onDeleteClicked = this.onDeleteClicked.bind(this);
        this.percentFormat = new Intl.NumberFormat("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }

    onDeleteClicked(e) {
        deleteDoc(doc(collection(doc(collection(firestore, "users"), auth.currentUser.uid), "monitors"), e.currentTarget.value))
            .catch(error => console.log("Error changing completed state: " + error));
    }

    render() {
        return (
            <div className="mdc-form-field">
                <span className="margin-right align-left queue-item">
                    {this.props.item.address}
                </span>
                <span className="margin-right">will alert at</span>
                <span className="align-left queue-item">
                    {this.percentFormat.format(this.props.item.utilization)}
                </span>
                <button onClick={this.onDeleteClicked} value={this.props.id} className="thick mdl-button mdl-js-button mdl-js-ripple-effect">
                    <i className="material-icons">delete</i>
                </button>
            </div>
        );
    }
}

export default QueueItem;